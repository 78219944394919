/*** Global ***/
@media (max-width:420px) {
    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {height: 100%;}
}
   
   /*** Home and Navigation ***/

    /*** Forms ***/

    /*** Ambassadors ***/
    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
    .ambassadors .board {height: 100%;}/* 130vw originally */
    .ambassadors .board1 {background-image: none;}
    .ambassadors .board1 h1, .ambassadors .board2 h1 {font-size: 5vw;}
    .ambassadors .board1 h2 {font-size: 5vw;}
    .ambassadors .board1 .text-block {width: 92vw; font-size: 4vw; left: 1vw;}
    .ambassadors .board1 .text-block p {width: 80vw;}
    .ambassadors .board1 .text-block p.tagline {margin: 0;}

    .ambassadors .board2 {margin-top: 0; padding-bottom: 4rem;}
    .ambassadors .board2 .text-blocks {left: 4vw;}
    .ambassadors .board2 .text-block1, .ambassadors .board2 .text-block2, .ambassadors .board2 .text-block3 {width: 95%; float: none;}
    .ambassadors .board2 .text-block1 h2, .ambassadors .board2 .text-block2 h2, .ambassadors .board2 .text-block3 h2 
        {background: none; padding-top: 0; margin-block-start: 8vw; margin-block-end: 1vw; text-align: left; font-size: 2.5rem;}
    .ambassadors .board2 .text-block1 p, .ambassadors .board2 .text-block2 p, .ambassadors .board2 .text-block3 p {text-align: left; width: 95%;}

    .ambassadors .board3 .text-block1 {float: none; position: relative; left: 5vw; width: 83vw;}
    .ambassadors .board3 h1 {font-size: 4vw;}
    .ambassadors .board3 ul {font-size: 3vw; margin: 2vw 0 2vw 9vw;}
    .ambassadors .board3 p {margin: 1vw 0; left: 6.5vw; width: 85vw; font-size: 3vw;}
    .ambassadors .board3 .text-block2 {background: 10vw 2vw/20% url(../images/ambassadors-qr-code.png) no-repeat; float: none; width: 83vw; height: 20vw;} 
    .ambassadors .board3 .text-block2 p {top: 3vw; width: 52vw; left: 29.5vw;}
    .ambassadors .board3 .text-block3 {float: none; height: 11vw; position: relative; top: 6vh;}
    .ambassadors .board3 .text-block3 p {left: 16vw;}
}

@media (max-width:420px) {
    .ambassadors .board1 .text-block p {width: 85%; font-size: 1.8rem;}
    .ambassadors .board2 .logo-block {top: 2vh; left: 0;}
    .ambassadors .board1 h1, .ambassadors .board2 h1 {font-size: 2.25rem;}
    .ambassadors .board2 .text-blocks {left: 12vw; width: 82%;}
    .ambassadors .board2 p {width: 85%; font-size: 1.5rem; line-height: 1.5;}
    .ambassadors .board2 .text-block1 h2, .ambassadors .board2 .text-block2 h2, .ambassadors .board2 .text-block3 h2 {width: 91%; font-size: 2rem;}
    .ambassadors .board3 p, .ambassadors .board3 ul li {font-size: 1.5rem; line-height: 1.5;}
    .ambassadors .board3 p {width: 80vw;}
    .ambassadors .board3 .text-block2 {display: none;}
    .ambassadors .board3 .text-block3 {float: left; height: auto; top: 0;}
    .ambassadors .board3 .text-block3 p {left: 5vw;}
    .ambassadors .board3 ul {margin: 2vw 0;}

}

    /*** Research ***/
    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
    .data-research .board1 {background: #045493;}
    .data-research .board1 .text-block, .data-research .board2 .text-block {width: 85%; left: 4%; top: 5%; position: relative;}
    .data-research .board1 .text-block p {margin-bottom: 3rem;}
    .data-research .board2 {background: none;}
    .data-research .board2 h1, .data-research .board2 p {width: 80%;}
    .data-research .board2 p {margin: 1rem 0 3.5rem 10%; font-size: 1.6rem; line-height: 1.5;}
    .data-research .board4 .text-block1 {background: none; padding: 0; width: 80%;}
    .data-research .board4 .text-block2 {background: none; padding: 0; width: 80%;}
    .data-research .board3 p, .data-research .board4 p, .data-research .board5 p, 
        .data-research .board6 p, .data-research .board7 p, .data-research .board5 li, 
        .data-research .board5 span, .data-research .board7 li, .data-research .board6 .text-block1 p, 
        .data-research .board6 .text-block2 p, .data-research .board6 .text-block3 p 
            {font-size: 2.5rem;}
    .data-research .board1, .data-research .board2, .data-research .board3,
    .data-research .board4, .data-research .board5, .data-research .board6 {height: auto;}
 
    .data-research .board5 .text-block1, .data-research .board5 .text-block2 {
        width: 90%; background: none; border-bottom: 1px solid #444; padding: 2rem 0;; position: relative; top: 0;}
    .data-research .board5 span {width: 100%;}
    .data-research .board6 .text-block1, .data-research .board6 .text-block2, .data-research .board6 .text-block3 {
        width: 90%; height: 100%; background: none; border-bottom: 1px solid #444; padding: 0;}
    .data-research .board7 .text-block1, .data-research .board7 .text-block2 {
        width: 90%; background: none; border-bottom: 1px solid #444; padding: 2rem 0;; position: relative; top: 0;}
    .data-research .board7 .stylized {background: top left/20% url(../images/dr-47.png) no-repeat; padding: 0 0 1rem 21rem;}
    .data-research .footnote {position: relative; width: 90%; margin: 0 auto;}
    .data-research .board7 li {line-height: 1.5; list-style: none;}    
}  
@media (max-width:420px) { 
    .data-research h1 {font-size: 2.25rem;}
    .data-research .board {width: 80%;}
    .data-research .board4 h1, .data-research .board5 h1, .data-research .board6 h1 {margin-bottom: 1rem;}
    .data-research .board4 .text-block1, .data-research .board5 .text-block1, .data-research .board6 .text-block1,
            .data-research .board4 .text-block2, .data-research .board5 .text-block2, .data-research .board6 .text-block2, 
            .data-research .board6 .text-block3 .data-research .board7 .text-block1, .data-research .board7 .text-block2 {top: 0; padding: 0;}
    .data-research .board4 p, .data-research .board5 p, .data-research .board6 p, 
            .data-research .board5 li, .data-research .board5 span,
            .data-research .board6 .text-block1 p, .data-research .board6 .text-block2 p , .data-research .board6 .text-block3 p,
            .data-research .board7 .text-block1 p, .data-research .board7 .text-block2 li, .data-research .footnote p
             { width: 100%; font-size: 1.5rem; line-height: 1.5; margin: 1rem auto; text-align: left;}
    .data-research .board6 .text-block3 {margin-top: 0;}
    .data-research .board7 ul {padding-inline-start: 0;}
    .data-research .board7 .stylized {padding: 0 0 1rem 6rem;}
    .data-research .board3, .data-research .board4, .data-research .board5, .data-research .board6, .data-research .board7 {padding: 1rem 0;}
}
    /*** LawHub ***/
    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
    .lawhub .board {height: auto; margin: 0; /*min-height: 48rem;*/}
    .lawhub .board1, .lawhub .board2 {background-image: none; position: relative;}
    .lawhub .board1 .logo-block {padding-top: 6vw; left: 3vw; width: 34%;}
    .lawhub .board1 h1 {font-size: 8vw;}
    .lawhub .board1 p.tagline {font-size: 2vw;}
    .lawhub .board1 .text-block {width: 90%; font-size: 4vw; top: 7vw;}
    .lawhub .board1 .text-block h2 {font-size: 5vw;}
    .lawhub .board1 .text-block p {width: 95%;}

    .lawhub .board2 .logo-block {position: absolute; bottom: 0;}
    .lawhub .board2 .quote {left: 9vw; top: 0; width: 87vw; background: no-repeat left top/6% url(../images/orange-quote.png);}
    .lawhub .board2 .quote p {font-size: 2.5vw; margin: 1rem 1rem 1rem 4.5rem; width: 80%;}
    .lawhub .board2 .text-block1, .lawhub .board2 .text-block2, .lawhub .board2 .text-block3 {float: none; width: 95%; border-bottom: 1px solid #FFF;}
    .lawhub .board2 h2 {font-size: 3.5vw;}
    .lawhub .board2 .text-blocks {position: relative; top: 0; margin: 0 0 0 7vw;}
    .lawhub .board2 .text-blocks p {font-size: 2.5vw;}

    .lawhub .board3 h1 {font-size: 6vw;}
    .lawhub .board3 .text-block {font-size: 3vw;}
    .lawhub .board3 p {width: 79%;}
    .lawhub .board3 .text-block1, .lawhub .board3 .text-block2, .lawhub .board3 .text-block3
    {float: none; width: 100%; font-size: 3vw;}
    .lawhub .board3 .percentage {background: none; display: inline;}
}

@media (max-width: 590px) {
    .lawhub .board1 .logo-block {padding-top: 0; background: url(../images/LH-north-star.png) top left/8vw no-repeat; width: 100%;}
    .lawhub .board1 h1 {font-size: 6vw; width: 47%;}
    .lawhub .board1 p.tagline {width: 47%; margin: 0;}
    .lawhub .board1 .text-block h2, .lawhub .board2 h2 {font-size: 3rem;}
    .lawhub .board2 .text-blocks p, .lawhub .board2 .quote p {font-size: 2rem;}
    .lawhub .board2 .quote {width: 86%; border-bottom: 1px solid #FFF;}
    .lawhub .board2 .text-blocks {margin: 0 0 0 3vw;}
    .lawhub .board2 .text-block1, .lawhub .board2 .text-block2, .lawhub .board2 .text-block3 {width: 80%;}

}

@media (max-width:420px) {
    .lawhub .board1 .logo-block {background: url(../images/LH-north-star.png) top left/8vw no-repeat;}
    .lawhub .board1 h1 {font-size: 2.5rem; margin-block-start: 0; margin-block-end: 0; width: 50%;}
    .lawhub .board1 .text-block {top: 2vh; width: 75vw; line-height: 1.75;}
    .lawhub .board1 .text-block h2 {line-height: 1.25;}
    .lawhub .board1 p, .lawhub .board2 .text-blocks p, .lawhub .board2 .quote p {font-size: 1.5rem;}
    .lawhub .board2 .logo-block {display: none;}
    .lawhub .board2 .quote {width: 70%; background: no-repeat 1.5vw top/10% url(../images/orange-quote.png);}
    .lawhub .board2 .quote p {margin: 1rem 1rem 1rem 4rem; width: 88%;}
    .lawhub .board2 .text-blocks {width: 73%; margin: 0 10%;}
    .lawhub .board2 .text-block1, .lawhub .board2 .text-block2, .lawhub .board2 .text-block3 {width: 100%;}
    .lawhub .board2 h2 {font-size: 2rem;}
    .lawhub .board3 {background: none; padding-left: 10%;}
    .lawhub .board3 p {width: 90%;}
    .lawhub .board3 .text-blocks {margin: 0;}
    .lawhub .board3 .text-block1, .lawhub .board3 .text-block2, .lawhub .board3 .text-block3 {width: 100%;}
}

@media (max-width: 350px) {
    .lawhub .board1 h1 {padding-left: 5%;}
}
/*
@media (max-width:360px) {
    .lawhub .board {height: 165vw;}
    .lawhub .board2 .quote {background: none; font-style: italic;}
    .lawhub .board2 .quote p {margin: 1rem; width: 80%;}
    .lawhub .board2 .text-blocks {margin: -3vw 0 0 8vw;}
    .lawhub .board2 .text-blocks p, .lawhub .board2 .quote p {font-size: 4vw;}
    .lawhub .board2 .logo-block {top: 149vw;}
}
*/

    /*** LawReady ***/
    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
    .lawready .board {position: relative;}
    .lawready .board1 .text-block {width: 70vw; font-size: 4vw; position: relative;}
    .lawready .board1 h2 {font-size: 5vw;}
}

@media (max-width: 590px) {
    .lawready .board {padding: 0 12%;}
    .lawready .board p {font-size: 1.5rem;}
    .lawready .board1 {background: #001a30;}
    .lawready .board1 .text-block {width: 80%; top: 4%; left: 4%;}
    .lawready .board1 h1, .lawready .board2 h1, .lawready .board3 h1 {font-size: 3rem; width: 100%; margin: 0;}
    .lawready .board2 .text-block1, .lawready .board3 .text-blocks {margin-left: 0;}
    .lawready .board2 .text-block2, .lawready .board2 .text-block3 {background: none; padding: 0;}
    .lawready .board3 {padding: 0 0 0 12%;}
    .lawready .board3 .text-block1, .lawready .board3 .text-block2, .lawready .board3 .text-block3 {width: 96%; margin: 0; background: none; padding: 0;}
    .lawready .board3 h2 {font-size: 2rem;}
}

    /*** LEC ***/
    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width: 700px) {
    .lec .board {position: relative;}
    .lec .board1 {background: #045493;}
    .lec .board1 h1 {font-size: 2.5rem;}
    .lec .board1 p {width: 85%;}
    .lec .board2 {min-height: 57rem;}
    .lec .board2 h1 {left: 10.6vw;}
    .lec .board2 p {font-size: 1.6rem; left: 11vw;}
    .lec .board2 p.roxanna-cruz {background: none; padding: 0.5rem 0; }    
}

@media (max-width:420px) {
    .lec .board.board2 {padding: 0 0 5rem 10%;}
    .lec .board.board1 {padding: 0 10% 5rem 0;}
    .lec .board1 h1 {width: 100%;}
    .lec .board1 .text-block {width: 95%; background:  top left/25% url(../images/lec-small.png) no-repeat;}
    .lec .board1 p {width: 92%;}
    .lec .board1 ul {left: 28%;}
    .lec .board2 h1 {left: 2vw; font-size: 2rem; width: 95%;}
    .lec .board2 p {left: 2vw; width: 95%;}
}

    /*** LSAT ***/
    /* For screens 700px wide or smaller - mobile phone experience */

@media (max-width:700px) {
    .lsat .board {position: relative; min-height: 100%; height: 100%;}
    .lsat .board p {font-size: 1.8rem;}
    .lsat .board1 .text-blocks {top: 12vh;}
    .lsat .board1 .text-block1, .lsat .board1 .text-block2, .lsat .board1 .text-block3 {width: 85%; display: block;}
    .lsat .board1 .text-block3 {margin-top: 0;}
    .lsat .board1 .stylized {background: none; transform: none; width: 85%; height: auto;}
    .lsat .board2 {background: #001a30; }
    .lsat .board2 .text-block1 {top: 1vh;}
    .lsat .board2 .text-block1 p {width: 100%;}
    .lsat .board2 .text-block2 {top: 2.5vh;}
    .lsat .board2 .text-block2 p {width: 92%; margin: 1rem 0 1rem 4rem;}
}

@media (max-width:420px) {
    .lsat .board1 {padding: 0 10% 0 0;}
    .lsat .board2 {padding: 0 0 0 10%;}
    .lsat .board {min-height: 43rem;}
    .lsat .board1 .text-blocks {top: 10vh; height: 100%;}
    .lsat .board1 .text-block1, .lsat .board1 .text-block2, .lsat .board1 .text-block3 {width: 95%; margin: 0; float: none; clear: both;}
    .lsat .board1 .stylized {height: 100%;}
    .lsat .board2 .text-block1 {padding: 18% 0 0 5%; background: 3vw 1vh/40% url(../images/lsat-logo.png) no-repeat;}
    .lsat .board2 .text-block2 {left: 0.5rem;}
    .lsat .board2 .text-block2 p {margin: 1rem;}
}


    /*** Matric ***/
    /* Mobile */
@media (max-width: 710px) {
    .matriculation .board1 {background: none;}
    .matriculation .board1 .text-block {width: 85%; left: 2vw; top: 4vh;}
    .matriculation .board1 .text-block p {width: 100%;}
    .matriculation .board2 p {font-size: 1.6rem;}
}

@media (max-width: 500px) {
    .matriculation .board2 .text-block2 {min-height: 9vh; top: 0;}
    .matriculation .board2 .text-block1 {width: 100%; left: 11vw; top: 0;}
    .matriculation .board2 .text-block1 p {padding: 0; margin: 1rem 0 2rem 0;}
    .matriculation .board2 .text-block2, .matriculation .board2 .text-block3 {background: none; left: 0; width: 100%;}
    .matriculation .board2 .text-block2 p, .matriculation .board2 .text-block3 p {padding: 0 0 0 0; margin: 1rem 0 2rem 4.5rem; width: 87%;} 
}

@media (max-width: 420px) {
    .matriculation .board1 .text-block {width: 85%; top: 1vh;}
    .matriculation .board2 .text-block2, .matriculation .board2 .text-block3 {width: 95%;}
}

    /*** PIF ***/

    /* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
    .pif .board p {font-size: 1.6rem;}
    .pif .board1 {background-image: none; padding: 0 10% 0 0;}
    .pif .board1 h1 {font-size: 2.5rem; width: 85vw; margin: 0;}
    .pif .board1 .text-block {width: 95%; font-size: 4vw; left: 0;}
    .pif .board1 ul li {height: auto; margin: 0 0 3vw 0;}
    .pif .board1 ul li span {padding: 0 0 1vw 2.5rem; background: left top/2rem url(../images/checkmark.png) no-repeat;}

    .pif .board2 {padding: 0 11%;}
    .pif .board2 h1 {margin: 2vw 0 0 0;}
    .pif .board2 .text-blocks {display: block; margin: 2vw 0 0 0;}
    .pif .board2 .text-blocks div {width: 100%;}
    .pif .board2 .text-block1, .pif .board2 .text-block2,
    .pif .board2 .text-block3, .pif .board2 .text-block4,
    .pif .board2 .text-block5 {background:none; padding: 2vw 0; text-align: left; font-size: 4vw; width: 20%; float: left; margin: 0;}
    .pif .board2 .text-blocks div.top-arrow, .pif .board2 .text-blocks div.bottom-arrow {display: none;}

    .pif .board3 {background: none; padding: 0 0 0 10%;}
    .pif .board3 h1 {font-size: 5vw; width: 80vw; left: 0; margin: 0;}
    .pif .board3 .text-block {left: 0; width: 100%;}
    .pif .board3 ul li {height: auto; margin: 0 0 3vw 0;}
    .pif .board3 ul li span {padding: 2vw 0 2vw 9vw; background: 2vw 1vh/2rem url(../images/checkmark.png) no-repeat;}   

}

@media (max-width:375px) {
    .pif .board1 {height: 100vw;}
    .pif .board1 ul li {margin: 0;}
    .pif .board1 ul li span, .pif .board3 ul li span {background: center left/2rem url(../images/checkmark.png) no-repeat;}
    .pif .board2 h1 {margin: 2vw 0 0 0; width: 100%;}
    .pif .board2 .text-blocks {margin: 7vw 0 0 0; width: 100%;}
    .pif .board3 {padding: 0 0 0 11%;}
}

@media (max-width:360px) {
    .pif .board {height: 106vw;}
    .pif .board1 ul li {margin: 0;}
    .pif .board1 ul li span {background: center left/2rem url(../images/checkmark.png) no-repeat;}
    .pif .board2 h1 {margin: 2vw 0 0 13vw;}
    .pif .board2 .text-blocks {margin: 7vw 0 0 12vw; width: 83%;}
    .pif .board3 h1 {left: 5vw;}
    .pif .board3 .text-block {left: 12vw;}
}


    /*** RISE ***/
    /* For screens 700px wide or smaller - small tablet and mobile phone experience */
@media (max-width:700px) {
    .rise .board {height: auto; position: relative;}
    .rise .board1 {background: center 50vw/100% url(../images/RISE.png) no-repeat;}
    .rise .board1 h1, .rise .board2 h1 {font-size: 5vw;}
    .rise .board1 p {font-size: 4vw; width: 63%;}
    .rise .board1 p.tagline {font-size: 4vw; width: 62%}
    .rise.content .board2 p {font-size: 1.5rem;}
    .rise .board2 h2 {width: 80%; font-size: 1.5rem; top: 0;}
    .rise .board2 .text-blocks .p1, .rise .board2 .text-blocks .p3, .rise .board2 .text-blocks .p5 {top: 0;}
    .rise .board2 p.right, .rise .board2 p.left {float: none; width: 80%;}
    .rise.content .board2 .jay-austin {background: 2vw 2vw/18% url(../images/jay-austin.png) no-repeat;}
    .rise.content .board2 .jay-austin p {margin: 0 0 1rem 2rem;}
}
@media (max-width: 500px) {
    .rise .board1 {background: none; padding: 0 10% 0 0;}
    .rise .board1 p.tagline {width: 83%;}
    .rise .board1 p {width: 95%; text-align: left;}
    .rise .board2 {padding: 0 0 0 10%; font-size: 1.5rem;}
    .rise .board2 .text-blocks p, .rise .board2 .text-blocks h2 {width: 95%;}
    .rise .board2 .text-blocks .p2 {top: 0;}
    .rise.content .board2 .jay-austin {background: none;}
    .rise.content .board2 .jay-austin p {width: 85%; margin: 1rem 0.5rem;}
}

@media (max-width:350px) {
    .rise .board2 h1 {left: 11vw;}
    .rise.content .board2 p {left: 6vw;}
    .rise .board2 p.tagline {left: 11vw;}
    .rise .board2 h2 {left: 10vw;}
    .rise .board2 p.right, .rise .board2 p.left {left: 11vw;}
    .rise.content .board2 .jay-austin p {margin: 0 0 1rem 1rem;}
}

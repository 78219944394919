/*   Colors provided by MGiorgi (marketing) and their contrast ratio analysis for accessibility
Tiles - background #045493 with #FFF text = 7.79:1 contrast ratio passes all
Tiles hover/focus - background #0085CA with #FFF text = 4.03:1 passes AA large text only
Tiles hover/focus - background #0085CA with #000 text = 5.2:1 contrast ratio passes all except AAA regular text
Main button - background #C75B12 with #FFF text = 4.25:1 contrast ratio passes AA large text only
Main button - background #C75B12 with #000 text = 4.93:1 contrast ratio passes all except AAA regular text
Main button hover/focus - background #EC7C32 with #000 text = 7.49:1 contrast ratio passes all
*/

/***** Responsive Styling for Laptop *****/
main.ui.container {max-width: 98%;}


/***** Global Styles *****/
button, .footer a {padding: 3rem; font-size: 2rem; text-decoration: none; border-style: none; border-color: none;}
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  }

html, body {
  height: 100%;
  font-size: 10px;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #001A30;
}
/* div {display: flex;} Overriding user agent styles for flexbox functionality*/

.App {
  display: flex;  
  flex-flow: row wrap;
  text-align: center; 
}

.App > * {
  padding: 10px;
  flex: 1 100%;
  max-width: 100%;
  overflow: hidden;
}

.main {
  text-align: left;
}

.content p {width: 75%; margin: 2rem auto;}

/***** Text Styles *****/
h1 {width: 100%; text-align: center; font-size: 3.5rem;}
h2 {font-size: 3.25rem;}
h3 {font-size: 3rem;}
h4 {font-size: 2.75rem;}
h5 {font-size: 2.5rem;}
h6 {font-size: 2.25rem;}
p, a, li, legend, label {font-size: 2rem;}
.board p, .board a, .board li {font-size: 1.6rem;}
.bold {font-weight: bold;}
.footer a.text-link {float: left; color: #0085CA; font-weight: bold; border: none; background: 0.5rem 3rem/10% url(./components/topics/images/feedback-arrow.png) no-repeat;}
.text-link:hover, .text-link:focus {text-decoration: underline;}

/***** Button Styles *****/
.main-btn  {background: #0085CA; color: #FFF; float: right; text-decoration: none; font-weight: bold;}
.main-btn:hover, .main-btn:focus {background: #045493;}
.secondary-btn {background: #0085CA; color: #FFF; float: left;}

/***** Navigation Styles *****/
.header {
  background: rgb(4, 84, 147, 0.10);
  flex-flow: row;
  height: 9rem; 
}

.navbar {width: 10%;}
.logo {width: 20vw; display: inline-block; position: relative; top:-1.75vw; left: 42vw;}
.logo img {height: 6rem;}

.footer {
  background: rgb(4, 84, 147, 0.10);
  flex-flow: row;
  width: 100%;
  margin-top: 2rem;
  padding: 2rem;
}


/* Sidebar navigation */
/* Position and sizing of burger button */
.bm-burger-button {
  width: 36px;
  height: 30px;
  position: relative;
  left: 2.75rem;
  top: 3rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #0085CA;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #444;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 4rem!important;
  width: 4rem!important;
  left: 2.5rem;
  top: 3rem!important;
}

.bm-cross-button span {top: 0!important; left: 1rem;}
/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  height: 4rem!important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
   top: 0;
  overflow: hidden;
}

/* General sidebar styles */
.bm-menu {
  background: #045493;
  font-size: 1em;
  overflow: hidden!important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  margin-top: 3em;
}

/* Individual item */
.bm-item {
  display: block;
  color: #FFF;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  padding: 1rem;
}

.bm-item:hover {
  color: #000;
background-color: #FFF; 
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}


/***** Home.js styles *****/
.home-screen {display: flex; flex-flow:row wrap; justify-content: center;}
/*.home-screen p {display: block; text-align: center; width: 70vw; font-size: 2vw;}*/
.home-screen p {text-align: center; width: 100%; font-size: 2vw; padding: 2rem 10%; margin: 0;}
.home-screen ul {list-style: none; display: flex; flex-flow: row wrap; margin-block-start: 0; margin-block-end: 0; padding-inline-start: 0;}
.home-screen ul li {padding: 3.5rem; background-color: skyblue; border-radius: 2.5rem; text-align: center; flex: 1 auto; margin: 2rem;} 
.home-screen h1, .home-screen h2 {color: #444; margin: 0;}
.home-screen h1 {font-size:6vw;}
.home-screen h2 {font-size: 4vw;}

/*****  Home.js Tiles Styling ******/
.tiles {width: 90%; display: block;}
.tile {
  background: #FFF;
  color: #0085CA;
  display: table;
  margin: 1rem;
  padding: 2rem;
  /*position: relative;*/
  text-align: center;
  text-decoration: none;
  transition: background-position 0.1s ease 0s;
  width: 25%;
  border: 0.25rem solid #0085CA;
  font-weight: bold;
}

.tile:hover, .tile:focus {
  background-position: 100% 0;
  background: #045493;
  color: #FFF;
  cursor: pointer;
  border: 0.25rem solid #045493;  
}

.tile-text {display: table-cell; vertical-align: middle;}


/***** Topic Pages - Carousel Styles *****/
/*.carousel .slide {height: 50vw;}*/
.carousel .slide {display: flex; height: 0;}
.carousel .slide.selected {height: auto;}
.carousel .slide .legend {opacity: 1;}
.carousel .slide .legend li {text-align: left;}
.carousel .thumbs-wrapper {margin: 0;}
.carousel .control-dots {display: none; height: 0; padding: 0; margin: 0;}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {height: 85.75%; opacity: 1; padding: 1rem; background: rgba(255, 255, 255, 0.5); border-radius: 0;}
.carousel .control-next.control-arrow:before {color: #000; border-left:8px solid #000;}
.carousel .control-prev.control-arrow:before {color: #000; border-right:8px solid #000;}
/*.carousel .carousel-status {width: 4vw; top: 52vw; color: #000; text-shadow: none; z-index: 100; font-size: 1vw;}*/
.carousel .carousel-status {display: none;}
.carousel.carousel-slider .control-arrow:hover, .carousel.carousel-slider .control-arrow:focus 
{background: rgba(255, 255, 255, 1); }
.carousel.carousel.carousel-slider .control-arrow {top: 1.5rem;}



/***** Feedback.js Styles *****/
.feedbackForm { width: 96%; margin: 0 auto; border: 1px solid #EEE;  display: block; 
  max-width: 100%; border-radius: 20px;  box-shadow: 0 4px 10px rgba(0,0,0,.25); padding: 1.5rem;}
.feedbackForm div {margin: 3rem auto;}
.form p {width: 90%;}
.feedbackForm p {margin: 0; width: 100%;}
.feedbackForm label {width: 100%; display: block; margin: 1rem 0; font-weight: 400;}
.feedbackForm input[type="text"], .feedbackForm textarea, .feedbackForm input[type="email"] {padding: 1rem; font-size: 1.5rem; width: 95%;}
ul.form-select {margin-block-start: 0; margin-block-end: 0; padding-inline-start: 0; margin: 1.5rem 0 0 0;} /* Chrome UA styles overrides */
.form-select li {list-style-type: none;}

.form-select label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
  text-align: center;
}
.feedbackForm fieldset {border: none; margin-inline-start: 0; padding-block-start: 0; padding-inline-start: 0; padding-inline-end: 0; padding-block-end: 0;}
.feedbackForm legend {font-weight: normal;}
.feedbackForm input[type="radio"] {display: inline; opacity: 0; z-index: 100; margin: 0; padding: 0; float: left; 
  padding-block: 0; padding-inline: 0; height: 0; width: 0;}
.feedbackForm input[type="radio"] + label {display: inline; float: left; color: #0085CA; margin: 0.5rem;  padding: 3rem 1rem;
  text-align: center; width: 18%; border: 0.25rem solid #0085CA;
}
.feedbackForm input[type="radio"]:checked + label {background: #045493;
  color: #FFF; border: 0.6rem solid #045493;}
.feedbackForm textarea {min-height: 10rem;}
.feedbackForm button {background: #0085CA; color: #FFF; font-weight: bold;}
.feedbackForm button:hover, .feedbackForm button:focus {background: #045493; color: #FFF; cursor: pointer;}

/***** MoreInfo.js Styles *****/
.contactForm { width: 100%; margin: 0 auto; border: 1px solid #EEE;  display: block; 
  max-width: 100%; border-radius: 2rem;  box-shadow: 0 4px 10px rgba(0,0,0,.25); padding: 1.5rem;}
.contactForm div {margin: 1rem auto 3rem auto; padding: 0.1rem; width: 100%;}
.contactForm input[type="text"], .contactForm input[type="email"] {padding: 1rem; font-size: 1.5rem; width: 95%; margin: 1rem 0 0 0;}

.contactForm fieldset {border: none; margin-inline-start: 0; padding-block-start: 0; padding-inline-start: 0; width: 100%; padding-inline-end: 0; padding-block-end: 0;}
.contactForm legend {font-weight: normal; margin-bottom: 1.5rem;}

.contactForm input[type="checkbox"] {display: inline-block; margin: 0; padding: 0;  
  padding-block: 0; padding-inline: 0; appearance: none; position:relative; outline: 0.25rem solid #0085CA; width: 100%; min-height: 9rem;}
.contactForm input[type="checkbox"] + label {left: 0;}
.contactForm input[type="checkbox"]:checked {background: #045493; width: 100%; min-height: 9rem; top: 0; left: 0; padding: 0.1rem;}
.contactForm input[type="checkbox"]:checked + label {left: 0; color: #FFF;}

.contactForm div.topics {margin: 0 3.8rem 3rem 0; width: 29%; display: inline-block; ;
  min-height: 9rem; position: relative;}
.contactForm input[type="checkbox"] + label {color: #0085CA; margin: 0.5rem; padding: 1rem 0;
  text-align: center;  cursor: pointer; width: 93%; position: absolute; top: 17%;}
  .contactForm input[type="checkbox"] + label.multiLine {top:0;}

  .contactForm div.topics.lec input[type="checkbox"] + label,
      .contactForm div.topics.pif input[type="checkbox"] + label  {top: 4%;}
  .contactForm div.topics.lec input[type="checkbox"] + label {padding: 0;}

.contactForm div.topics .contactForm input[type="checkbox"]:checked {background: #045493; color: #FFF; border: 0.6rem solid #045493;}
.contactForm sup {font-size: 1.2rem;}
.contactForm button {background: #0085CA; color: #FFF; font-weight: bold;}
.contactForm button:hover, .contactForm button:focus {background: #045493; color: #FFF; cursor: pointer;}
.contactForm div.topics.selectAll {width: 95.5%;}

.thankYou {position: relative; left: 11.5vw;}
.solution-sheets {display: flex; flex-flow:row wrap; justify-content: center;}
.textLabel {width: 90%;}

/***** Media Queries *****/
@media (min-width: 1024px) {
  .contactForm div.topics.lec input[type="checkbox"] + label {padding: 1.5rem 0 0 0;}
}

@media (max-width: 880px) {
  .contactForm div.topics {margin: 0 2rem 2rem 0; width: 30%; }
  .contactForm input[type="checkbox"] + label {width: 95%;}
  .contactForm input[type="checkbox"] + label {font-size: 2.3rem;}
  .contactForm div.topics.selectAll {width: 96%;}
} 

@media all and (min-width: 800px) {
  .header  { order: 0; }
  .main    { order: 1; flex: 3 0px; }
  .footer  { order: 2; }
}

@media all and (max-width: 767px) {
  .tile {width: 100%; margin: 1rem;} 
  .footer {padding: 0;}
  .footer a {width: 92%; float: left;}
  .footer a.text-link {background: none;}
  .carousel.carousel.carousel-slider .control-arrow {top:0;} 
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {height: 92%;}
  .carousel .carousel-status {display: none;} 
  
}
@media (max-width: 762px) {.contactForm div.topics.dr input[type="checkbox"] + label {top: 4%;}}

@media all and (min-width: 710px) and (max-width: 850px) {
  div.ui.container {margin: 0; max-width: 98%;}
  .contactForm {margin: 0; width: 96%;}
}

@media all and (max-width: 710px) {
  div.ui.container {margin: 0 auto; max-width: 90%;}
  .contactForm, .feedbackForm {margin: 0; width: 95%; padding: 0 1.5rem; box-shadow: none; border: none;}
  .contactForm button {width: 91vw;} 
  .contactForm div.topics.mc, .contactForm div.topics.pif {min-height: 9rem;} 
  .contactForm div {margin: 0 auto;}
  .contactForm input[type="checkbox"] {min-height: 6rem;} 
  .contactForm div.topics.pif input[type="checkbox"] + label {top: -1rem;}
  .contactForm input[type="text"], .contactForm input[type="email"] {margin: 1rem 0 1.5rem 0;}
  .board p {font-size: 2.5rem;}

  .carousel .slide {display: flex; height: 0;}
  .carousel .slide.selected {height: auto;}

}


/* For tablets */
@media all and (min-width: 768px) {.tile {width: 23%; margin: 1rem;}}

/* For screens 700px wide or smaller - mobile phone experience */
@media (max-width:700px) {
  .carousel p.carousel-status {display: none;}
  .home-screen p {font-size: 4vw; width: 80vw;}
  .logo {left: 38vw;}
  .feedbackForm input[type="radio"] + label {width: 24%;}
  .contactForm div.topics, .contactForm div.topics.mc, .contactForm div.topics.pif, .contactForm div.topics.selectAll {width: 100%; min-height: 6rem;}
  .contactForm input[type="checkbox"] + label {top: 5%;}
  .contactForm div.topics.pif input[type="checkbox"] + label, .contactForm div.topics.lec input[type="checkbox"] + label {top: 3%;}
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {height: 90%;}
}

/* For small phones */
@media (max-width:525px) {
  .feedbackForm input[type="radio"] + label {width: 37%; padding: 1rem;}
  .feedbackForm button {width: 100%; margin:0 auto;}
  .contactForm {border: none; box-shadow: none;}
  .contactForm div.topics.lec, .contactForm div.topics.pif {min-height: 6rem; }  /* Needed at 437 and smaller */
  .contactForm div.topics.pif input[type="checkbox"] + label, .contactForm div.topics.lec input[type="checkbox"] + label {font-size: 5.5vw; top: 9%;}
}

@media (max-width:393px) {.contactForm div.topics.pif {min-height: 6rem; }}

/* For large monitors */
@media (min-width: 1248px) {
  .home-screen p {width: 74vw;}
  .contactForm div.topics {width: 30%;}
  .contactForm div.topics.mc input[type="checkbox"] + label, 
  .contactForm div.topics.pif input[type="checkbox"] + label, 
  .contactForm div.topics.lec input[type="checkbox"] + label {top: 17%; left: 1rem;}
}



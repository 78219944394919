/***** Styles specifically for the LawHub topic page *****/

.lawhub .board {color: #FFF; background: #001a30; margin: 0; height: auto; padding: 0; min-height: 39rem;} 
.lawhub li.slide {background: #001a30;}

/**** Board 1 Specific ****/
.lawhub .board1 {background: #001a30 url(../images/LH-Hub-journey-stages.png) top right no-repeat;  background-size: 53vw;}
.lawhub .board1 .logo-block {width: 25%; position: relative; top: 1.5vw; left: 5vw; padding-top: 6vw; background: url(../images/LH-north-star.png) top center no-repeat;}
.lawhub .board1 h1 {margin-bottom: 0; font-size: 6vw; font-family: 'Open Serif', serif; width: 100%; font-weight: 900;}
.lawhub h1 sub {font-size: 1.5vw;}
.lawhub .board1 p.tagline {margin: 0 auto; text-align: center; font-size: 1.5vw; top:0; width: 100%;  position: relative; left: 0;}
.lawhub .board1 .text-block {position: relative; top: 4vh; left: 5vw; width: 60vw; font-size: 2vw; text-align: left;}
.lawhub .board1 .text-block h2 {font-family: Open Serif; font-size: 2.5rem; margin-block-start: 0; margin-block-end: 0;}
.lawhub .board1 .text-block p {width: 76%; margin: 1rem 0 0 0; line-height: 1.5;}


/**** Board 2 Specific ****/
.lawhub .board2 {background: no-repeat left top/24% #001a30 url(../images/LH-image.png); height: auto; min-height: 45rem;}
.lawhub .board2 h1 {font-size: 2vw; font-family: 'Open Serif', serif;}
.lawhub .board2 h2 {font-size: 2vw;}
.lawhub .board2 p {font-size: 1.5vw; text-align: left;}
.lawhub .board2 .logo-block { background: no-repeat center/40% url(../images/LH-north-star.png); position: absolute; bottom: 0; right: 4vw; width: 10vw; height: 10vw; padding-top: 6vw;}
.lawhub .board2 .logo-block sub {font-size: 0.5vw;}
.lawhub .board2 .quote {width: 78%; position: relative; left: 22vw; top: 4vh; background: no-repeat 2vw top/9% url(../images/orange-quote.png);}
.lawhub .board2 .quote p {font-size: 2vw; text-align: left; }
.lawhub .board2 .quote .stylized {visibility: hidden;}
.lawhub .board2 .text-block1, .lawhub .board2 .text-block2, .lawhub .board2 .text-block3 {display: inline-block; width: 31%; float: left; padding: 0; margin: 0;}
.lawhub .board2 .text-blocks {position: relative; top: 4vh; left: 3vw;}
.lawhub .board2 .text-blocks p { width: 90%; font-size: 1.6rem; text-align: left; line-height: 1.5; margin: 1rem;}
.lawhub .board2 .text-block1 p, .lawhub .board2 .text-block2 p {margin-left: 3vw;}

/**** Board 3 Specific ****/
.lawhub .board3 {background: #001a30 no-repeat 2vw 2vw/6% url(../images/LH-north-star.png);}
.lawhub .board3 h1 {font-size: 4vw; font-family: 'Open Serif', serif;}
.lawhub .board3 p {margin: 0 auto; width: 60%; font-size: 1.5rem; line-height: 1.5;}
.lawhub .board3 .percentage {color: #E25536; font-size: 5vw; font-weight: 900; display: block; height: 22vw; background: center/108% url(../images/lawhub-percent-bg.png); padding: 7vw 2.5vw 0;}
.lawhub .board3 .text-block {font-size: 2vw; position: relative;}
.lawhub .board3 .text-blocks {font-size: 1.5vw; margin: 0 0 0 7vw;}
.lawhub .board3 .text-block1, .lawhub .board3 .text-block2, .lawhub .board3 .text-block3 {width: 30%; float: left; padding: 0; margin: 2vh 0;}
.lawhub .board3 .text-blocks p {line-height: 1.5; width: 90%;}


/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .lawhub .board {margin: 0;}
    .lawhub .board1 {background: #001a30 top right/50% url(../images/LH-Hub-journey-stages.png) no-repeat; min-height: 53rem; }
    .lawhub .board1 .text-block h2 {font-size: 3rem;}
    .lawhub .board1 .text-block p {font-size: 2.5rem; width: 85%;}
    .lawhub .board2 {min-height: 100%;}
    .lawhub .board2 .logo-block {right: 1vw;}
    .lawhub .board2 .text-blocks p, .lawhub .board3 p {font-size: 2rem;}
    .lawhub .board2 .quote p {line-height: 1.5;}
    .lawhub .board3 .text-block {top: 1vw;}
    .lawhub .board3 .text-block1, .lawhub .board3 .text-block2, .lawhub .board3 .text-block3 {margin: 3vw 0;}
}
/***** Styles specifically for the PIF topic page *****/

.pif .board {color: #444; background: #FFF; margin: 0; min-height: 39rem;}
.pif .board li {font-size: 1.6rem;}
/**** Board 1 Specific ****/
.pif .board1 {background: #FFF top left/60% url(../images/PIF.png) no-repeat;  background-size: 29vw;}
.pif .board1 h1 {font-size: 4vw; text-align: left; color: #045493; margin: 0 0 0 31vw; width: 45vw;}
.pif .board1 .text-block {position: relative; top: 1vw; left: 32vw; width: 57vw; font-size: 2vw; text-align: left;}
.pif .board1 .text-block p {width: 55vw; line-height: 1.5; margin: 2rem 0; font-weight: bold;}
.pif .board1 ul { list-style: none; padding-inline-start: 0;}
.pif .board1 ul li {height: 3.4rem; margin: 0 0 1vw 0;}
.pif .board1 ul li span {padding: 1vw 0 1vw 6vw; display: inline-block; vertical-align: middle; background: 2vw 1vh/2rem url(../images/checkmark.png) no-repeat;}

/**** Board 2 Specific ****/
.pif .board2 h1 {font-size: 4vw; text-align: left; color: #045493; margin: 2vw 0 0 10vw;}
.pif .board2 .text-blocks {font-size: 2vw; display: flex; width: 91%; margin: 7vw 0 0 4vw;}
.pif .board2 .text-blocks div {width: 20%;}
.pif .board2 .text-block1 {background: #FFF top center/100% url(../images/pif-1.png) no-repeat; padding: 15vw 0 0 0;}
.pif .board2 .text-block2 {background: #FFF top center/100% url(../images/pif-2.png) no-repeat; padding: 15vw 0 0 0; margin: 4vw 0 0 0;}
.pif .board2 .text-block3 {background: #FFF top center/100% url(../images/pif-3.png) no-repeat; padding: 15vw 0 0 0;}
.pif .board2 .text-block4 {background: #FFF top center/100% url(../images/pif-4.png) no-repeat; padding: 15vw 0 0 0; margin: 4vw 0 0 0;}
.pif .board2 .text-block5 {background: #FFF top center/100% url(../images/pif-5.png) no-repeat; padding: 15vw 0 0 0;}
.pif .board2 .text-blocks div.top-arrow { width: 5%; background: #FFF top center/100% url(../images/over-arrow.png) no-repeat;}
.pif .board2 .text-blocks div.bottom-arrow { width: 5%; background: #FFF 0 13vw/100% url(../images/under-arrow.png) no-repeat;}

/**** Board 3 Specific ****/
.pif .board3 {background: #FFF right 2vh/48% url(../images/pif-3-bg.png) no-repeat;}
.pif .board3 h1 {font-size: 4vw; text-align: left; color: #045493; margin: 0 0 0 5vw; width: 45vw; position: relative; top: 3vw;}
.pif .board3 .text-block {position: relative; top: 6vw; left: 3vw; width: 45vw; font-size: 2vw; text-align: left;}
.pif .board3 ul { list-style: none; padding-inline-start: 0;}
.pif .board3 ul li {height: 3.4rem; margin: 0 0 2vw 0;}
.pif .board3 ul li span {padding: 1vw 0 1vw 6vw; display: inline-block; vertical-align: middle; background: 2vw 1vh/2rem url(../images/checkmark.png) no-repeat;}



/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .pif .board3 {background: #FFF right 2vw/35% url(../images/pif-3-bg.png) no-repeat; height: 42rem;}
    .pif .board3 .text-block {width: 58vw;}
}





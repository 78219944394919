/***** Styles specifically for the LEC topic page *****/

.lec .board {color: #FFF; background: #045493; margin: 0 auto; min-height: 39rem; }

/**** Board 1 Specific ****/
.lec .board1 {background: #045493 top right/33% url(../images/lec-1-bg.png) no-repeat; }
.lec .board1 h1 { width: 60%; text-align: left; position: relative; top: 1vh; left: 4vw;}
.lec .board1 p {position: relative; top: 1vh; left: 4vw; width: 60%; margin: 0; text-align: left; line-height: 1.25; font-size: 1.6rem;}
.lec .board1 .text-block {
    background:  top left/28% url(../images/lec-small.png) no-repeat;
    position: relative;
    top: 3vh;
    left: 4vw;
    width: 48vw;
    font-size: 2.5vw;
    text-align: left;
}
.lec .board1 ul {list-style: none; padding-inline-start: 0; font-weight: bold; position: relative; left: 35%;}
.lec .board1 li {line-height: 1.6;}

/**** Board 2 Specific ****/
.lec .board2 h1 {margin-bottom: 0; font-size: 4vw; width: 45%; position: relative; top: 3vw; left: 8.6vw; text-align: left;}
.lec .board2 p {top:5vw; width: 85%; position: relative; left: 9vw; font-size: 1.75vw; margin: 0; text-align: left; line-height: 1.25;}
.lec .board2 p.quote {background: top left/7% url(../images/white-quote.png) no-repeat; padding: 0 0 0 6.5vw; margin: 2vw 0;}
.lec .board2 p.roxanna-cruz {background: top left/10% url(../images/roxanna-cruz.png) no-repeat; padding: 2vw 12vw 2vw 9vw;}
.lec .board2 a {color: #FFF; font-weight: bold;}
.lec .board2 .line-break {display: block;}

/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .lec .board {min-height: 48rem;}
    .lec .board p, .lec .board li, .lec .board a {font-size: 2.5rem;}
    .lec .board2 p.roxanna-cruz {padding: 2vw 0 2vw 9vw;}
    .lec .board2 {height: 72rem;}
}



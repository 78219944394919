/***** Styles specifically for the Ambassadors topic page *****/

.ambassadors .board {color: #FFF; background: #045493; margin: 0 auto; position: relative; height: 100%; min-height: 39rem; width: 100%;}

/**** Board 1 Specific ****/
.ambassadors .board1 {background: #045493 top left/30% url(../images/ambassadors1-bg.png) no-repeat;}
.ambassadors .board1 h1 {font-size: 3vw; width: 100%; text-align: left;}
.ambassadors .board1 .text-block {position: relative; top: 1vw; left: 32vw; width: 65vw; font-size: 2vw; text-align: left;}
.ambassadors .board1 .text-block p {width: 55vw; line-height: 1.5; margin: 2rem 0;}
.ambassadors  .board1 p.tagline {margin: 0 auto; font-size: 4vw; line-height: 1; font-weight: bold; top:0; width: 100%; position: relative; left: 0;}
.ambassadors .board1 .text-block p.tags span {text-transform: uppercase;}

/**** Board 2 Specific ****/
.ambassadors .board2 {font-size: 2vw; text-align: left; }
.ambassadors .board2 .logo-block {position: relative; top: 1vh; left: 7vw;}
.ambassadors .board2 h1 {font-size: 3vw; text-align: center; width: 81vw;}
.ambassadors .board2 .text-blocks {position: relative; left: 6vw; top: 0; height: 100%;}
.ambassadors .board2 .text-block1 h2 {background: 8vw top/40% url(../images/elevate.png) no-repeat; padding-top: 12vw; text-align: center; margin-block-start: 0;
    margin-block-end: 0;}
.ambassadors .board2 .text-block2 h2 {background: 6.5vw top/40% url(../images/enhance.png) no-repeat; padding-top: 12vw; text-align: center; margin-block-start: 0;
    margin-block-end: 0;}
.ambassadors .board2 .text-block3 h2 {background: 8vw top/40% url(../images/optimize.png) no-repeat; padding-top: 12vw; text-align: center; margin-block-start: 0;
    margin-block-end: 0;}
.ambassadors .board2 .text-block1, .ambassadors .board2 .text-block2 {width: 28%; float: left; padding: 0; margin: 2vh 2vw 0 0; text-align: center;}
.ambassadors .board2 .text-block3 {width: 27%; float: left; padding: 0; margin: 2vh 0 0 0;}
.ambassadors .board2 p {margin: 0; text-align: center; width: 100%;}
.ambassadors .board2 .text-blocks h2 {text-transform: uppercase; font-size: 2rem;}
.ambassadors .board2 .text-block {position: relative; top: 8vw; left: 28vw; width: 65vw; font-size: 2vw; text-align: center;}

/**** Board 3 Specific ****/
.ambassadors .board3 {margin-top: -5vw; padding-top: 5vw;}
.ambassadors .board3 h1 {font-size: 3vw; text-align: left; margin: 2vw 0 2vw 6.5vw;}
.ambassadors .board3 .text-block1 {width: 67vw; float: left;}
.ambassadors .board3 p {font-size: 2vw; text-align: left; width: 60vw; margin: 1rem auto; position: relative; left: 3vw;}
.ambassadors .board3 ul {font-size: 2vw; text-align: left; list-style: disc; margin: 2vw 0 2vw 6vw;}
.ambassadors .board3 li {margin: 0.5rem 0;}
.ambassadors .board3 .text-block2 {background: center 7vw/90% url(../images/ambassadors-qr-code.png) no-repeat; float: left; width: 30%; height: 44vw;}
.ambassadors .board3 .text-block2 p {position: relative; top: 33vw; width: 18vw; font-weight: bold; left: 0.5vw;}
.ambassadors .board3 .text-block3 {float: left; background: #FFF; width: 100%;  color: #444; height: 4vw;}
.ambassadors .board3 .text-block3 p {width: 100%; margin: 0; position: relative; top: 0; left: 0; padding: 1vw 7vw;}


/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .ambassadors .board1 .text-block p, .ambassadors .board2, .ambassadors .board3 p {font-size: 2.5rem;}
    .ambassadors .board2 {margin-top: 0;}
    .ambassadors .board2 .logo-block {top: 1vh;}
    .ambassadors .board2 .text-blocks {top: 0;}
    .ambassadors .board2 .text-blocks h2 {font-size: 3rem;}
    .ambassadors .board2 h1 {left: 5%;}
    .ambassadors .board2 p {margin: 1rem 0 2rem 5%; font-size: 2.5rem;}
    .ambassadors .board3 h1, .ambassadors .board4 h1, .ambassadors .board5 h1, .ambassadors .board6 h1, .ambassadors .board7 h1 {font-size: 3.5rem;}
    .ambassadors .board3 h2, .ambassadors .board4 h2, .ambassadors .board5 h2, .ambassadors .board6 h2, .ambassadors .board7 h2 {font-size: 3rem;}
    .ambassadors .board3 ul {margin: 2vw 0 2vw 4vw;}
    .ambassadors .board3 li, .ambassadors .board3 a {font-size: 2.5rem;}
}

@media (max-width: 768px) {
    .ambassadors .board3 .text-block1 {width: 70%;}
    .ambassadors .board3 p {width: 57vw;}
}







/***** Styles specifically for the LawReady topic page *****/

.lawready .board {color: #FFF; background: #001a30; margin: 0 auto; height: 100%; width: 100%; min-height: 39rem;}


/**** Board 1 Specific ****/
.lawready .board1 {background: #001a30 -14vw 7vw/58% url(../images/LR-1.png) no-repeat; height: 100%;}

.lawready .board1 .logo-block {position: relative; top: 10vw; left: 8vw;}
    .lawready .board1 h1 {margin-bottom: 0; font-size: 6vw; width: 100%; text-align: left;}
    .lawready h1 sup {font-size: 1.5vw;}
    .lawready .board1 .text-block {
    position: relative;
    top: 7vw;
    left: 37vw;
    width: 48vw;
    font-size: 2vw;
    line-height: 1.5;
    text-align: left;
}
    .lawready .board1 .text-block p {width: 101%;}


/**** Board 2 Specific ****/
.lawready .board2 {background: #FFF; color: #444; text-align: left; font-size: 2.25vw;}
.lawready .board2 h1 {font-size: 4vw; color: #045493; text-align: left; margin: 5vw 0 0 5vw;}
.lawready .board2 .text-block1 {width: 96%; margin: 0 0 0 5vw;}
.lawready .board2 .text-block2 {background: 5vw 0/10% url(../images/LR-assessments.png) no-repeat; padding: 0 0 1rem 16vw;}
.lawready .board2 .text-block3 {background: 5vw 0/10% url(../images/LR-certificate.png) no-repeat; padding: 0 0 1rem 16vw;}
.lawready.content .board2 p {margin: 2rem 0; width: 92%;}

/**** Board 3 Specific ****/
.lawready .board3 {background: #FFF; color: #444;}
.lawready .board3 h1 {font-size: 4vw; color: #045493; text-align: left; margin: 3vw 0 2vw 6.5vw;}
.lawready .board3 .text-blocks {margin: 0 0 0 5vw; width: 98%;}
.lawready .board3 .text-block1, .lawready .board3 .text-block2, .lawready .board3 .text-block3 {
    width: 30%; float: left; margin: 0 0 0 2vw; font-size: 1.75vw; padding: 6vw 0 0 0;
}
.lawready .board3 .text-block1 {background: 10vw 1vw/20% url(../images/LR-portfolio.png) no-repeat;}
.lawready .board3 .text-block2 {background: 10vw 1vw/20% url(../images/LR-certificate.png) no-repeat;}
.lawready .board3 .text-block3 {background: 10vw 1vw/20% url(../images/LR-dashboard.png) no-repeat;}
.lawready .board3 h2 {font-size: 2.5vw; text-align: left;}
.lawready .board3 ul {list-style: none; padding-inline-start: 0; width: 96%; margin: 0;}
.lawready .board3 ul li {margin: 0 0 1rem 0; text-align: left; font-size: 1.4rem;}

/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .lawready .board p, .lawready .board3 ul li {font-size: 2.5rem;}
    .lawready .board1 {min-height: 50rem;}
    .lawready .board1 .text-block {width: 55%; top: 3vh;}
    
}






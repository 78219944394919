/***** Styles specifically for the DataResearch topic page *****/

.data-research .board {color: #FFF; background: #045493; margin: 0 auto; position: relative; height: 100%; width: 100%; min-height: 35rem;}

/**** Board 1 Specific ****/
.data-research .board1 {background: #045493 top left/30% url(../images/research-1.png) no-repeat;}
.data-research .board1 h1 {width: 100%; text-align: left;}

    .data-research .board1 .text-block {
    position: relative;
    top: 18%;
    left: 35%;
    width: 48vw;
    text-align: left;
}
    .data-research .board1 .text-block p {width: 100%;}


/**** Board 2 Specific ****/
.data-research .board2 {background: #FFF top right/30% url(../images/research-2.png) no-repeat; color: #444; padding: 2rem 0;}
.data-research .board2 h1 {color: #045493; width: 60%; text-align: left; position: relative; left: 10%; margin: 2vh 0 0 0;}
.data-research .board2 p {text-align: left; width: 60%; margin: 2rem 0 0 10%;}
.data-research .board2 .text-block {
    position: relative;
    top: 4%;
    left: 0;
    text-align: left;
}

/**** Boards 3-7 ****/
.data-research .board3, .data-research .board4, .data-research .board5, 
.data-research .board6, .data-research .board7 {background: #FFF; color: #444; padding: 3rem 0;}

.data-research .board3 h1, .data-research .board4 h1, .data-research .board5 h1, 
.data-research .board6 h1, .data-research .board7 h1 {
    font-size: 2.5rem; 
    margin: 0; 
    color: #045493; 
}

.data-research .board3 h2, .data-research .board4 h2, .data-research .board5 h2, 
.data-research .board6 h2, .data-research .board7 h2 {font-size: 2rem; margin: 0; color: #045493;}

.data-research .board3 p, .data-research .board4 p, .data-research .board5 p, 
.data-research .board6 p, .data-research .board7 p, .data-research .board5 li,
.data-research .board5 span, .data-research .board7 li {font-size: 1.6rem; width: 90%;}

.data-research .footnote {position: relative; width: 100%; bottom: 0;}
.data-research .footnote p {font-size: 1.15rem;}

.data-research .text-block1, .data-research .text-block2 {
    width: 45%; 
    display: inline-block; 
    position: relative;
    top: 3vh;
}

.data-research .board3 .text-block1 {background: center top/20% url(../images/dr-2-1.png) no-repeat; padding: 15% 0 0 0;}
.data-research .board3 .text-block2 {background: center 5vh/35% url(../images/dr-2-2.png) no-repeat; padding: 15% 0 0 0;}

.data-research .board4 .text-block1 {background: center top/20% url(../images/dr-3-1.png) no-repeat; padding: 15% 0 0 0;}
.data-research .board4 .text-block2 {background: center 2vh/35% url(../images/dr-3-2.png) no-repeat; padding: 15% 0 0 0;}

.data-research .board5 .text-block1 {top: 5vh; background: center top/30% url(../images/dr-4-1.png) no-repeat; padding: 12% 0 0 0;}
.data-research .board5 .text-block2 {background: center 2vh/35% url(../images/dr-4-2.png) no-repeat; padding: 15% 0 5% 0;}
.data-research .board5 .text-block1 span {margin: 1rem 0; display: block;}
.data-research .board5 ul {list-style: none; padding-inline-start: 0;}

.data-research .board6 .text-block1, .data-research .board6 .text-block2, .data-research .board6 .text-block3 {
    width: 30%;
    height: 24vh;
    display: inline-block;
    vertical-align: top;
} 
.data-research .board6 .text-block1 p, .data-research .board6 .text-block2 p, .data-research .board6 .text-block3 p {font-size: 1.5rem;}
.data-research .board6 .text-block1 {background:  center top/20% url(../images/reputation.png) no-repeat; padding: 5% 0 0 0;}
.data-research .board6 .text-block2 {background:  center top/20% url(../images/financial-aid.png) no-repeat; padding: 5% 0 0 0;}
.data-research .board6 .text-block3 {background:  center top/20% url(../images/academic-support.png) no-repeat; padding: 5% 0 0 0; margin-top: 3vh;}
.data-research .board6 .bold.break {display: block;}

.data-research .board7 .text-block1, .data-research .board7 .text-block2 {padding-bottom: 1rem;}
.data-research .board7 .text-block1 {top: 1vh; width: 42%; margin-right: 2rem;}
.data-research .board7 .text-block1 p {text-align: left;}
.data-research .board7 .stylized {color: #045493; font-weight: bold; background: top right/30% url(../images/dr-47.png) no-repeat; text-align: left; padding-right: 13rem; margin-top: 4rem;}
.data-research .board7 li {text-align: left; list-style: none; line-height: 1.5; margin-bottom: 1rem;}
.data-research .board7 ul {padding-inline-start: 0;}

/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .data-research .board {min-height: 39rem;}
    .data-research .board1 .text-block p, .data-research .board2, .data-research .board3 p, .data-research .board4 p, 
        .data-research .board5 p, .data-research .board6 p, .data-research .board7 p, .data-research .board3 p, 
        .data-research .board4 p, .data-research .board5 p, .data-research .board6 p, .data-research .board7 p, 
        .data-research .board5 li, .data-research .board5 span, .data-research .board7 li, .data-research .board6 .text-block1 p, 
        .data-research .board6 .text-block2 p, .data-research .board6 .text-block3 p {font-size: 2.5rem;}
    .data-research .board2 h1 {left: 5%;}
    .data-research .board2 p {margin: 1rem 0 2rem 5%; font-size: 2.5rem;}
    .data-research .board3 h1, .data-research .board4 h1, .data-research .board5 h1, .data-research .board6 h1, .data-research .board7 h1 {font-size: 3.5rem;}
    .data-research .board3 h2, .data-research .board4 h2, .data-research .board5 h2, .data-research .board6 h2, .data-research .board7 h2 {font-size: 3rem;}
    .data-research .footnote {position: relative; margin-top: 7%;}
    .data-research .footnote p {font-size: 1.8rem;}
    .data-research .board6 .text-block1, .data-research .board6 .text-block2, .data-research .board6 .text-block3 {height: auto;}
}



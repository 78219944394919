/***** Styles specifically for the Matriculation Survey topic page *****/

.matriculation .board {position: relative; color: #FFF; background: #045493; margin: 0; height: auto; font-size: calc(16px + 5 * ((100vw - 320px) / 680));}
.matriculation h1 {margin-bottom: 0; font-size: 2.6rem; width: 80%; text-align: left;}

/**** Board 1 Specific ****/
.matriculation .board1 {background: #045493 right top/30% url(../images/Matric-Cert.png) no-repeat; }

.matriculation .board1 .text-block {
    position: relative;
    width: 80%;
    top: 0;
    left: 5vw;
    line-height: 1.25;
    text-align: left;
}

.matriculation .board1 p {margin: 2rem 0; font-size: 1.6rem;}

/**** Board 2 Specific ****/
.matriculation .board2 {background: 100% #045493; text-align: left; }
.matriculation .board2 p {font-size: 1.8rem;}
.matriculation .board2 .text-block1 {position: relative; top: 2vw; left: 5vw;}
.matriculation .board2 .text-block1 p {width: 85%; margin: 1rem 0 3rem;}
.matriculation .board2 .text-block2 {background: 1vw 0vw/10% url(../images/matric-26.png) no-repeat; min-height: 16vh; height: auto; position: relative; left: 4vw; top: 1vw;}
.matriculation .board2 .text-block3 {background: 1vw 0vw/10% url(../images/matric-18.png) no-repeat; min-height: 16vh; height: auto; position: relative; left: 4vw;}
.matriculation .board2 .text-block2 p {width: 78%; padding: 2.5vw 0 0 1vw;}

.matriculation.content, .matriculation .carousel-root, .matriculation .carousel.carousel-slider, .matriculation .carousel .slider-wrapper,
.matriculation .carousel .slider {height: auto; background: #045493; }

/**** Media Queries ****/

/* 4K Display */
@media (min-width:1200px) {
   /* .matriculation .board {top: -4vh;}*/
    .matriculation .board1 {height: 35rem; background: #045493 right -10vh/30% url(../images/Matric-Cert.png) no-repeat;}
    .matriculation .board1 .text-block {top: 4vh; width: 80%; left: 4vw;}
    .matriculation .board2 .text-block1 p {width: 90%;}
    .matriculation .board2 .text-block2 {top: -1vh; padding: 0 0 3rem 0; background: 2vw 2vw/15% url(../images/matric-26.png) no-repeat; width: 40%; display: inline-block; }
    .matriculation .board2 .text-block3 {padding: 0 0 2rem 0; background: 2vw 0vw/10% url(../images/matric-18.png) no-repeat; width: 60%; display: inline-block; }
    .matriculation .board2 .text-block2 p {position: relative; margin: 0 0 0 8vw; width: 80%;}
    .matriculation .board2 .text-block3 p {margin: 0 0 0 9vw;}
}

/* Laptop */
@media (min-width: 1000px) {
    .matriculation .board1 {height: 40rem;}
}

/* Tablet 
@media (max-width: 950px) {
    .matriculation .board1 .text-block {left: 37vw; width: 75%;}
}
*/
@media (max-width: 800px) {
    /*.matriculation .board1 {background: #045493 0 5vh/36% url(../images/Matric-Cert.png) no-repeat;}
    .matriculation .board1 .text-block {left: 37vw; width: 75%; top: 7vh;}*/
    .matriculation .board2 .text-block1 {left: 8vw;}
    .matriculation .board2 .text-block2 {left: 7vw; min-height: 8vh;} 
    .matriculation .board2 .text-block2 p {padding: 1rem 0 0 1rem;}
    .matriculation .board2 .text-block3 {left: 7vw;}
}




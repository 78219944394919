/***** Styles specifically for the RISE Alliance topic page *****/

.rise .board {color: #444; background: #FFF; margin: 0 auto; min-height: 39rem;}

/**** Board 1 Specific ****/
.rise .board1 {background: #FFF bottom center/80% url(../images/RISE.png) no-repeat; min-height: 44rem;}
.rise .board1 h1 {margin-bottom: 0; font-size: 3vw; width: 100%; color: #045493;}
.rise h1 sup {font-size: 1.5vw;}
.rise .board1 p.tagline {font-size: 3.5vw; color: #045493; margin: 0 auto; text-align: center; top:0; width: 57%; position: relative; left: 0;}
.rise .board1 p {width: 89%; font-size: 2vw;}


/**** Board 2 Specific ****/
.rise .board2 { text-align: left; font-size: 1.75vw;}
.rise .board2 h1 {margin-bottom: 0; font-size: 3vw; width: 100%; color: #045493; text-align: left; position: relative; left: 5vw;}
.rise.content .board2 p {text-align: left; position: relative; left:0; width: 88%; margin: 1rem auto; font-size: 1.4rem;}
.rise .board2 p.tagline {font-size: 3.5vw; color: #045493; margin: 0; top:0; position: relative; left: 5vw; width: 95%;}
/*.rise .board2 h2 {position: relative; left: 5.5vw; color: #045493; font-size: 1.6rem; margin: 3vw 0 1vw; width: 48%;}*/
.rise .board2 .text-blocks {position: relative; top: 0; left: 6%; padding: 0;}
.rise .board2 .text-blocks p, .rise .board2 .text-blocks h2 {width: 45%; margin: 1vw 0; position: relative; display: inline-block;}
.rise .board2 .text-blocks h2 {color: #045493; font-size: 1.6rem; margin: 0; padding: 0;}
.rise .board2 .text-blocks .p1 {top: -3rem; left: 0;}
.rise .board2 .text-blocks .p2 {top: 1.5rem; left: 0;}
.rise .board2 .text-blocks .p3 {top: -3rem; left: 0;}
.rise .board2 .text-blocks .p4 {top: 0; left: 0;}
.rise .board2 .text-blocks .p5 {top: -3rem; left: 0;}
.rise .board2 .text-blocks .p6 {top: 0; left: 0;}

.rise.content .board2 .jay-austin {background: 2vw top/8% url(../images/jay-austin.png) no-repeat; margin: 0; position: relative; left: 4.5%;}
.rise.content .board2 .jay-austin p {margin: 1rem 1rem 1rem 9rem; width: 75%;}
.rise .board2 a {font-size: 1.4rem;}


/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .rise .board1 {min-height: 65rem;}
    .rise.content .board2 p {font-size: 2rem;}
    .rise .board2 h1, .rise .board2 p.tagline {left: 6vw;}
    .rise.content .board2 .text-blocks p, .rise .board2 .text-blocks h2  {font-size: 2rem; width: 44%;}
    .rise .board2 .text-blocks h2 {margin-block-start: 0; margin-block-end: 0;}
    .rise .board2 .text-blocks .p2, .rise .board2 .text-blocks .p4, .rise .board2 .text-blocks .p6 {left: 3rem;}
    .rise .board2 .text-blocks .p4 {top: -1.5rem;}
    .rise.content .board2 .jay-austin {padding: 0 0 1rem 0;}
    .rise.content .board2 .jay-austin p {margin: 1rem 1rem 1rem 13rem;}
}

@media (min-width: 1024px) and (orientation: landscape) {
    .rise .board2 .text-blocks h2, .rise.content .board2 .text-blocks p { width: 40%;}
    .rise .board2 .text-blocks .p2, .rise .board2 .text-blocks .p4, .rise .board2 .text-blocks .p6 {left: 6rem;}
}
/***** Styles specifically for the LSAT topic page *****/

.lsat .board {color: #FFF; background: #001a30; margin: 0 auto; height: 100%; min-height: 39rem; width: 100%;}
.lsat p {font-size: 1.6rem;}

/**** Board 1 Specific ****/
.lsat .board1 {background: #001a30 no-repeat 17vw 2vw/65% url(../images/lsat-logo.png); height: 100%;}

.lsat .board1 .text-blocks {
    position: relative;
    top: 28vw;
    left: 5vw;
    width: 95%;
    font-size: 2vw;
    text-align: left;
}

.lsat .board1 .text-block1, .lsat .board1 .text-block2, .lsat .board1 .text-block3 {width: 27%; float: left; margin: 0 4vw 0 0;}
.lsat .board1 .text-blocks p {width: 100%; text-align: center;}
.lsat .board1 .stylized {color: #E25536; display: block; width: 16vw; height: 8vw; margin: 0 0 0 7vw; background: 11.5vw 5vw/ 20% url(../images/lsat-arrow.png) no-repeat; transform: rotate(-10deg);}
.lsat .board1 .text-block3 {margin-top: -8vw;}

/**** Board 2 Specific ****/
.lsat .board2 {background: #001a30 no-repeat right 7vw/40% url(../images/lsat-2.png);}
.lsat .board2 p {text-align: left;}
.lsat .board2 .text-block1 {
    width: 100%; position: relative; top: 3vh; left: 0; 
    font-size: 2.25vw; background: 10vw 2vh/40% url(../images/lsat-logo.png) no-repeat; 
    padding: 18% 0 0 11%;}
.lsat .board2 .text-block1 p {width: 51%; margin: 0;}
.lsat .board2 .text-block2 {background: #E7F5F7; width: 100%; color: #444; padding: 0.5rem; position: relative; top: 6vh; left: 0;}
.lsat .board2 .text-block2 p {width: 49%; margin: 1rem 0 1rem 8rem; color: #001A30;}

/**** Media Queries ****/

/* Laptop and high resolution tablets - landscape */
@media (min-width: 1024px) {
    .lsat .board p {font-size: 2.5rem;}
    .lsat .board1 , .lsat .board2 {min-height: 54rem;}
    .lsat .board1 .stylized {margin: -15% 0 7% 7vw; background: 12vw 6vw/ 20% url(../images/lsat-arrow.png) no-repeat; /*height: 10vw;*/}
    .lsat .board2 .text-block2 {top: 9vh;}
    .lsat .board2 .text-block2 p {width: 75%; /*height: 12rem;*/}
}




